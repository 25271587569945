<template>
    <div>
		<b-modal ref="modalSaillie" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("monte.add_iart") }}
				</template>
			</template>

			<div class="col-12">
				<div class="row mb-3">
					<label class="col-3 col-form-label">{{ $t('global.date') }} *</label>
					<div class="col-9">
						<div class="row">
							<e-datepicker class="col" v-model="acte_day" mode="date"></e-datepicker>
						</div>
					</div>
				</div>
				<div class="row mb-3">
					<label class="col-3 col-form-label">{{ $t('monte.mare') }} *</label>
					<div class="col-9">
						<div class="row">
							<e-select
                                v-model="selected_mares"
                                id="horse_id"
                                track-by="horse_id"
                                label="horse_nom"
                                :options="mares"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                                :group-select="false"
                                :multiple="true"
                            >
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
						</div>
					</div>
				</div>

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("global.ajouter") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Gynecologie from "@/mixins/Gynecologie.js"
import Common from '@/assets/js/common'
import ActeType from '@/mixins/ActeType.js'
import Actes from '@/mixins/Actes.js'

export default {
	name: "ModalSaillie",
	mixins: [Gynecologie, ActeType, Actes],
	data () {
		return {
			processing: false,
			table_busy: false,
			acte_day: new Date(),
			selected_acte_type: null,
			actes_type_saillie: [],
			loading_acte_type: false,
			extern_slot_columns: [
				'saillie_time'
			],
			mares: [],
			selected_mares: []
		}
	},
	methods: {
		async init_component() {
			this.actes_type_saillie = await this.getActeTypeSaillie()
			this.selected_acte_type = this.actes_type_saillie.find(acte => acte.actestype_code == 'REPRO_INSMINATION_ARTIFICIELLE_EN_RFRIGR_TRANSPORT')
		},
		openModal(mares) {
			this.$refs.modalSaillie.show()
			this.mares = mares
			this.selected_mares = mares
		},
		closeModal() {
			this.$refs.modalSaillie.hide()
		},
		async checkForm() {
			this.processing = true

			if(!this.selected_acte_type || this.selected_mares.length == 0) {
				this.processing = false
				return false
			}

			let acte_date = new Date(this.acte_day.getFullYear() + '/' + parseInt(this.acte_day.getMonth()+1) + '/' + this.acte_day.getDate())
			acte_date.setHours(this.acte_day.getHours())
			acte_date.setMinutes(this.acte_day.getMinutes())

			let acte_status = 1
			if(this.acte_date > new Date()) {
				acte_status = 0
			}

			let mare_ids = this.selected_mares.map(mare => mare.horse_id)

			let acte_ids = await this.addActes(mare_ids, acte_status, acte_date, '', this.selected_acte_type.actestype_id, undefined, null, [], [], null, null)
			if(acte_ids.length > 0) {
				this.$router.push({ name: 'acteAjout', params: {acte_id: acte_ids.join(','), state: 'modification', from: 'monteReservation'} })
			}
		}
	},
	components: {
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
	}
}
</script>